@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');

.text-sphere {
    top: 110px;
    width: 500px;
    height: 290px;
    justify-content: center;
    align-items: center;
}

.tagcloud {
    justify-content: flex-end;
    padding-top: 10px;
    top: 0;
    left: 30px;
    width: 450px;
    height: 400px;
    font-family: 'Inter', 'sans-serif';
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1em;
}

.tagcloud--item {
    color: #00ffffff;
    text-transform: uppercase;
}

.tagcloud--item:hover {
    color: orange;
}

