@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
      @apply font-[Raleway];
  }
  li {
      @apply px-4;
      @apply cursor-pointer;
  }
}


.content-div {
  background-repeat:no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    #FF7E5F,
    #FEB47B
  )!important;
}

.content-div2 {
  background-repeat:no-repeat;
  background-size: 100%;
  background-position: center;
  height: 750px;

}