/* TypingAnimation.css */
.typing-animation {
    display: inline-block;
    font-weight: bold;
    font-size:xx-large;
    color: #f97316; /* Tailwind orange-500 color */
    white-space: nowrap;
    overflow: hidden;
  }
  
  .cursor {
    display: inline-block;
    width: 2px;
    background-color: currentColor;
    margin-left: 2px;
    animation: blink 1s infinite;
  }
  
  .cursor-hidden {
    opacity: 0;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  